import { grpc } from "@improbable-eng/grpc-web";
import { HandlerChainBuilder, type Handler, type RequestMetadata } from "../handlers/HandlerChainBuilder";
import { Injectable } from "../dependency-injection/Injectable";
import type { CameraKitConfiguration } from "../configuration";
import { configurationToken } from "../configuration";
import type { Result } from "../common/result";
import { Err, Ok } from "../common/result";
import type { FetchHandler } from "../handlers/defaultFetchHandler";
import { cameraKitServiceFetchHandlerFactory } from "../handlers/cameraKitServiceFetchHandlerFactory";
import type { RequestStateEventTarget } from "../handlers/requestStateEmittingHandler";
import {
    createRequestStateEmittingHandler,
    requestStateEventTargetFactory,
} from "../handlers/requestStateEmittingHandler";
import { MetricsDefinition } from "../generated-proto/pb_schema/camera_kit/v3/service";

export interface GrpcRequest {
    serviceName: string;
    methodName: string;
    requestType: grpc.ProtobufMessageClass<grpc.ProtobufMessage>;
    responseType: grpc.ProtobufMessageClass<grpc.ProtobufMessage>;
}

export type GrpcHandler = Handler<GrpcRequest, GrpcResult, RequestMetadata>;

export type GrpcResult = Result<
    grpc.UnaryOutput<grpc.ProtobufMessage> & { status: grpc.Code.OK },
    grpc.UnaryOutput<grpc.ProtobufMessage>
>;

export const GRPC_CALL_REQUEST_TYPE = "grpc_call";

export interface GrpcRequestDimensions extends Record<string, string> {
    requestType: typeof GRPC_CALL_REQUEST_TYPE;
    methodName: string;
}

/**
 * Returns true if the given string is the name of a metric gRPC method.
 */
function isMetricsGrpcMethod(method: string) {
    return (
        method === MetricsDefinition.methods.setOperationalMetrics.name ||
        method === MetricsDefinition.methods.setBusinessEvents.name
    );
}

/**
 * An Injectable handler that can make requests to the CameraKit backend service via grpc-web. This handler can be
 * passed to {@link createTsProtoClient} to produce a well-typed service client.
 *
 * @internal
 */
export const grpcHandlerFactory = Injectable(
    "grpcHandlerFactory",
    [configurationToken, cameraKitServiceFetchHandlerFactory.token, requestStateEventTargetFactory.token] as const,
    (
        configuration: CameraKitConfiguration,
        fetchHandler: FetchHandler,
        requestStateEventTarget: RequestStateEventTarget
    ): GrpcHandler => {
        const host = `https://${configuration.apiHostname}`;

        const fetchHandlerWithMetrics = new HandlerChainBuilder(fetchHandler).map(
            // TODO: ideally we don't need it here: https://jira.sc-corp.net/browse/CAMKIT-6350
            createRequestStateEmittingHandler<GrpcRequestDimensions>(requestStateEventTarget)
        ).handler;

        // We define our own Transport so that we can use our custom `fetch` implementation. This is important for two
        // reasons:
        //   1. Our custom fetch includes features like retries that we want to use for these requests.
        //   2. Applications may override this fetch implementation (via our DI system) to support more advanced
        //      use-cases.
        const transport: grpc.TransportFactory = (options) => {
            let metadata: grpc.Metadata | undefined = undefined;
            const controller = globalThis.AbortController ? new AbortController() : undefined;
            let cancelled = false;
            return {
                sendMessage(msgBytes) {
                    const requestInit = {
                        headers: metadata?.toHeaders() ?? {},
                        method: "POST",
                        body: msgBytes,
                        signal: controller?.signal,
                    };
                    const metricsDimensions: GrpcRequestDimensions = {
                        requestType: GRPC_CALL_REQUEST_TYPE,
                        methodName: options.methodDefinition.methodName,
                    };
                    // Note: Currently, we do not report network metrics for the metrics requests
                    // themselves because that triggers an infinite loop of metrics reporting. Ideally,
                    // we still want to report them, but attach them to the next metrics request without
                    // triggering it.
                    const request = isMetricsGrpcMethod(options.methodDefinition.methodName)
                        ? fetchHandler(options.url, requestInit)
                        : fetchHandlerWithMetrics([options.url, metricsDimensions], requestInit);
                    request
                        .then((response) => {
                            options.onHeaders(new grpc.Metadata(response.headers), response.status);
                            return response.arrayBuffer();
                        })
                        .then((body) => {
                            if (cancelled) return;
                            options.onChunk(new Uint8Array(body));
                            options.onEnd();
                        })
                        .catch((error) => {
                            if (cancelled) return;
                            cancelled = true;
                            options.onEnd(error);
                        });
                },

                start(m) {
                    metadata = m;
                },

                finishSend() {},

                cancel() {
                    if (cancelled) return;
                    cancelled = true;
                    controller?.abort();
                },
            };
        };

        return async (request) =>
            new Promise((resolve) => {
                grpc.unary(
                    {
                        methodName: request.methodName,
                        service: { serviceName: request.serviceName },
                        requestStream: false,
                        responseStream: false,
                        requestType: request.requestType,
                        responseType: request.responseType,
                    },
                    {
                        request: new request.requestType(),
                        host,
                        onEnd: (response) => {
                            if (isUnaryOutputOk(response)) {
                                resolve(Ok(response));
                            } else {
                                resolve(Err(response));
                            }
                        },
                        transport,
                    }
                );
            });
    }
);

function isUnaryOutputOk(
    value: grpc.UnaryOutput<grpc.ProtobufMessage>
): value is grpc.UnaryOutput<grpc.ProtobufMessage> & { status: grpc.Code.OK } {
    return value.status === grpc.Code.OK;
}
