import { assertUnreachable } from "../../common/assertions";
import { Injectable } from "../../dependency-injection/Injectable";
import { scan } from "../../events/scan";
import type { CofDimensions } from "../../remote-configuration/cofHandler";
import { COF_REQUEST_TYPE } from "../../remote-configuration/cofHandler";
import type {
    Dimensions,
    RequestStateEventTarget,
    RequestStateEvents,
} from "../../handlers/requestStateEmittingHandler";
import { requestStateEventTargetFactory } from "../../handlers/requestStateEmittingHandler";
import { getPlatformInfo } from "../../platform/platformInfo";
import type { MetricsClient } from "../../clients/metricsClient";
import { metricsClientFactory } from "../../clients/metricsClient";
import { Count } from "../operational/Count";
import { Histogram } from "../operational/Histogram";
import type { Timer } from "../operational/Timer";
import {
    LENS_CORE_JS_REQUEST_TYPE,
    LENS_CORE_WASM_REQUEST_TYPE,
    type LensCoreDownloadDimensions,
} from "../../lens-core-module/loader/lensCoreFactory";
import { GRPC_CALL_REQUEST_TYPE, type GrpcRequestDimensions } from "../../clients/grpcHandler";
import type { AssetDownloadDimensions, LensDownloadDimensions } from "./reportLensAndAssetDownload";

type InProgressMap = Map<number, { timer: Timer<"download_latency"> }>;
interface InProgress {
    name: "inProgress";
    inProgress: InProgressMap;
}
interface Completed {
    name: "completed";
    inProgress: InProgressMap;
    dimensions: Record<string, string>;
    timer: Timer<"download_latency">;
    downloadSizeKb: number;
}
type RequestState = InProgress | Completed;

type ReportableMetricDimensions =
    | LensDownloadDimensions
    | AssetDownloadDimensions
    | CofDimensions
    | LensCoreDownloadDimensions
    | GrpcRequestDimensions;

const reportableMetricRequestTypes: ReportableMetricDimensions["requestType"][] = [
    "asset",
    "lens_content",
    LENS_CORE_JS_REQUEST_TYPE,
    LENS_CORE_WASM_REQUEST_TYPE,
    COF_REQUEST_TYPE,
    GRPC_CALL_REQUEST_TYPE,
];

const getAdditionalDimensions = (dimensions: ReportableMetricDimensions): [string, string][] => {
    switch (dimensions.requestType) {
        case COF_REQUEST_TYPE:
            return [["delta", dimensions.delta]];
        case GRPC_CALL_REQUEST_TYPE:
            return [["method", dimensions.methodName]];
        case LENS_CORE_JS_REQUEST_TYPE:
        case LENS_CORE_WASM_REQUEST_TYPE:
            return [["custom", dimensions.customBuild]];
        default:
            return [];
    }
};

const getContentType = (dimensions: ReportableMetricDimensions): string => {
    if (dimensions.requestType === "asset") {
        return dimensions.assetType;
    }
    return dimensions.requestType;
};

const getSizeKb = (event: RequestStateEvents): number => {
    switch (event.type) {
        case "started":
        case "errored":
            return 0;
        case "completed":
            return event.detail.sizeByte / 1024;
        default:
            assertUnreachable(event);
    }
};

const getStatus = (event: RequestStateEvents): string => {
    switch (event.type) {
        case "started":
        case "errored":
            // We'll use status 0 to indicate that an exception occurred during the request. This is somewhat in keeping
            // with browsers that set the response status to 0 if the request was not able to be made (e.g. CORs
            // preflight failed, or the user canceled the request).
            return "0";
        case "completed":
            return event.detail.status.toString();
        default:
            assertUnreachable(event);
    }
};

export const isRelevantRequest = (value: Dimensions): value is ReportableMetricDimensions => {
    const reportableMetricDimensions = value as ReportableMetricDimensions;
    return reportableMetricRequestTypes.includes(reportableMetricDimensions.requestType);
};

export const reportHttpMetrics = Injectable(
    "reportHttpMetrics",
    [metricsClientFactory.token, requestStateEventTargetFactory.token] as const,
    (metrics: MetricsClient, requestStateEventTarget: RequestStateEventTarget) => {
        scan<RequestState>({ name: "inProgress", inProgress: new Map() })(
            requestStateEventTarget,
            ["started", "completed", "errored"],
            (state, event) => {
                const { inProgress } = state;
                const { dimensions, requestId } = event.detail;

                if (!isRelevantRequest(dimensions)) return state;

                switch (event.type) {
                    case "started":
                        const timer = event.detail.timer;
                        inProgress.set(requestId, { timer });
                        return { name: "inProgress", inProgress };
                    case "completed":
                    case "errored":
                        const completedRequest = inProgress.get(requestId);
                        if (!completedRequest) return state;
                        inProgress.delete(requestId);

                        const downloadSizeKb = getSizeKb(event);
                        const status = getStatus(event);
                        const operationalDimensions: Record<string, string> = {
                            content_type: getContentType(dimensions),
                            network_type: getPlatformInfo().connectionType ?? "unknown",
                            status,
                        };

                        for (const [key, value] of getAdditionalDimensions(dimensions)) {
                            operationalDimensions[key] = value;
                        }

                        completedRequest.timer.measure(operationalDimensions);

                        return {
                            name: "completed",
                            inProgress: state.inProgress,
                            dimensions: operationalDimensions,
                            downloadSizeKb,
                            timer: completedRequest.timer,
                        };
                    default:
                        assertUnreachable(event);
                }
            }
        ).addEventListener("state", ({ detail: state }) => {
            if (state.name !== "completed") return;

            const { dimensions, timer, downloadSizeKb } = state;

            metrics.setOperationalMetrics(Count.count("download_finished", 1, dimensions));
            metrics.setOperationalMetrics(Histogram.level("download_size_kb", downloadSizeKb, dimensions));
            metrics.setOperationalMetrics(timer);
        });
    }
);
