import lensCoreWasm from "../../lensCoreWasmVersions";
import type { CameraKitConfiguration } from "../../configuration";
import { configurationToken } from "../../configuration";
import { Injectable } from "../../dependency-injection/Injectable";
import type { FetchHandler } from "../../handlers/defaultFetchHandler";
import { defaultFetchHandlerFactory } from "../../handlers/defaultFetchHandler";
import type { InitialEmscriptenModule, LensCoreModule } from "../generated-types";
import { getLogger } from "../../logger/logger";
import { createLensCore } from "../lensCore";
import { loadScript } from "../../common/loadScript";
import { HandlerChainBuilder } from "../../handlers/HandlerChainBuilder";
import { createCustomLensCoreHandler } from "../../handlers/customLensCoreHandler";
import type { RequestStateEventTarget } from "../../handlers/requestStateEmittingHandler";
import {
    createRequestStateEmittingHandler,
    requestStateEventTargetFactory,
} from "../../handlers/requestStateEmittingHandler";
import { getRequiredBootstrapURLs } from "./bootstrapURLs";

const logger = getLogger("lensCoreFactory");

const findMatch = (regex: RegExp, strings: string[]) => strings.find((s) => regex.test(s));

export const LENS_CORE_JS_REQUEST_TYPE = "lens_core_js";
export const LENS_CORE_WASM_REQUEST_TYPE = "lens_core_wasm";

export interface LensCoreDownloadDimensions extends Record<string, string> {
    requestType: typeof LENS_CORE_JS_REQUEST_TYPE | typeof LENS_CORE_WASM_REQUEST_TYPE;
    customBuild: string;
}

/**
 * This component is responsible for:
 *   1) Loading LensCore WebAssembly (WASM) assets
 *   2) Using the WASM assets to initialize the LensCore WASM module
 *
 * By default, WASM assets will be loaded from the Bolt CDN – but if `endpoint` is provided, assets will be loaded
 * using it as a base URL.
 *
 * @internal
 */
export const lensCoreFactory = Injectable(
    "lensCore",
    [defaultFetchHandlerFactory.token, configurationToken, requestStateEventTargetFactory.token] as const,
    async (
        handler: FetchHandler,
        { lensCoreOverrideUrls, wasmEndpointOverride }: CameraKitConfiguration,
        requestStateEventTarget: RequestStateEventTarget
    ) => {
        let lensCoreJS: string;
        let lensCoreWASM: string;

        const customBuild = !!(lensCoreOverrideUrls || wasmEndpointOverride);
        let lensCoreHandlerChainBuilder = new HandlerChainBuilder(handler);
        if (customBuild) {
            // We only use custom handler when LensCore is loaded from a custom location.
            lensCoreHandlerChainBuilder = lensCoreHandlerChainBuilder.map(createCustomLensCoreHandler());
        }
        // add metrics to LC requests
        const lensCoreHandler = lensCoreHandlerChainBuilder.map(
            // TODO: ideally we don't need it here: https://jira.sc-corp.net/browse/CAMKIT-6350
            createRequestStateEmittingHandler<LensCoreDownloadDimensions>(requestStateEventTarget)
        ).handler;

        if (lensCoreOverrideUrls) {
            lensCoreJS = lensCoreOverrideUrls.js;
            lensCoreWASM = lensCoreOverrideUrls.wasm;
        } else {
            const endpointOverride = wasmEndpointOverride ?? undefined;
            const assetURLs = await getRequiredBootstrapURLs(endpointOverride);

            lensCoreJS = findMatch(/\.js/, assetURLs) ?? "";
            lensCoreWASM = findMatch(/\.wasm/, assetURLs) ?? "";

            if (!lensCoreJS || !lensCoreWASM) {
                throw new Error(
                    `Cannot fetch required LensCore assets. Either the JS or WASM filename is missing from ` +
                        `this list: ${assetURLs}.`
                );
            }

            // Fetching here and creating an Object URL lets LensCore optimized loading itself in a WebWorker,
            // otherwise the glue script would need to be downloaded again.
            const glueScript = await lensCoreHandler([
                lensCoreJS,
                { requestType: LENS_CORE_JS_REQUEST_TYPE, customBuild: `${customBuild}` },
            ]).then((r) => r.blob());
            lensCoreJS = URL.createObjectURL(glueScript);
        }

        const scriptElement = await loadScript(lensCoreJS);

        const lensCore = await new Promise<InitialEmscriptenModule & LensCoreModule>((resolve, reject) => {
            let initialModule: Partial<InitialEmscriptenModule>;
            // will trigger WASM initialization and data loading,
            // after completion it will be safe to call imported WASM functions
            // More about emscripten initialization:
            // eslint-disable-next-line max-len
            // https://emscripten.org/docs/getting_started/FAQ.html?highlight=modularize#how-can-i-tell-when-the-page-is-fully-loaded-and-it-is-safe-to-call-compiled-functions
            const moduleInit = globalThis.createLensesModule(
                (initialModule = {
                    // url will be used for loading glue JS during Worker initialization
                    mainScriptUrlOrBlob: lensCoreJS,
                    // will be triggered by Emscripten during the initialization
                    instantiateWasm: (importObject, receiveInstance) => {
                        WebAssembly.instantiateStreaming(
                            lensCoreHandler([
                                lensCoreWASM,
                                { requestType: LENS_CORE_WASM_REQUEST_TYPE, customBuild: `${customBuild}` },
                            ]),
                            importObject
                        )
                            .then(function ({ instance, module }) {
                                receiveInstance(instance, module);
                                // compiled module will be reused in Worker
                                initialModule.compiledModule = module;
                                resolve(moduleInit);
                            })
                            .catch(reject);
                    },
                })
            );
        });

        // now when we have LensCore WASM in memory we can release the script element
        scriptElement.remove();

        // print warning if loaded version differs from hardcoded one
        if (lensCoreWasm.version != `${lensCore.getCoreVersion()}`) {
            logger.warn(
                `Loaded LensCore version (${lensCore.getCoreVersion()}) differs from expected one (${
                    lensCoreWasm.version
                })`
            );
        }

        return createLensCore(lensCore);
    }
);
