import type { FileWithHandle, fileOpen } from "browser-fs-access";
import { Injectable } from "../dependency-injection/Injectable";

/**
 * Default file picker dialog type.
 */
export type OpenDefaultFilePicker = typeof fileOpen;

/**
 * File picker options. For example, it indicates which MIME types of files are expected to be picked.
 */
export type FilePickerOptions = Parameters<OpenDefaultFilePicker>[0];

/**
 * File picker dialog result object, which is a Promise that resolves to a file or an array of files with handles.
 */
export type FilePickerResult = Promise<FileWithHandle | FileWithHandle[]>;

/**
 * A callback to show a file picker dialog.
 * @param options - Options for the file picker dialog.
 * @param openDefaultPicker - The default file picker function. The client app may choose to use
 * the default file picker.
 * @returns A promise that resolves to the result of the file picker dialog.
 */
export type FilePicker = (options: FilePickerOptions, openDefaultPicker: OpenDefaultFilePicker) => FilePickerResult;

/**
 * Default file picker factory function. The file picker is triggered when a lens issues a file request.
 * Client applications can override this factory function to provide their custom file picker.
 */
export const filePickerFactory = Injectable(
    "filePicker",
    (): FilePicker => (options, openDefaultPicker) => openDefaultPicker(options)
);
