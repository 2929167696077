import { lensImagePickerError } from "../namedErrors";
import { Injectable } from "../dependency-injection/Injectable";
import { getLogger } from "../logger/logger";
import { lensCoreFactory } from "../lens-core-module/loader/lensCoreFactory";
import type { LensCore } from "../lens-core-module/lensCore";
import { pickClientImage } from "./imagePicker";
import type { FilePicker } from "./filePicker";
import { filePickerFactory } from "./filePicker";

const logger = getLogger("lensClientInterface");

/**
 * Registers client interface handler in LensCore.
 *
 * @internal
 * @param lensCore LensCore instance to register in.
 * @param sessionErrors EventTarget to dispatch errors in.
 */
export const registerLensClientInterfaceHandler = Injectable(
    "registerLensClientInterfaceHandler",
    [lensCoreFactory.token, filePickerFactory.token] as const,
    (lensCore: LensCore, filePicker: FilePicker) => {
        // Make sure we are compatible with previous LensCore versions
        if (!lensCore.setClientInterfaceRequestHandler) {
            logger.warn("Current LensCore version doesn't support lens client interface requests");
            return;
        }

        lensCore.setClientInterfaceRequestHandler(async ({ data, interfaceControl, interfaceAction }) => {
            if (
                interfaceControl === lensCore.InterfaceControl.ImagePicker &&
                interfaceAction === lensCore.InterfaceAction.Show
            ) {
                await pickClientImage(data, lensCore, filePicker).catch((error) => {
                    logger.error(
                        lensImagePickerError(
                            "Error occurred while attempting to select an image file for the lens request.",
                            error
                        )
                    );
                });
            }
        });
    }
);
